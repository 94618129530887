<template>
    <div>
        <div class="profile-in">
            <div class="form-group">
                <label class="form-control-label">E-mail</label>
                <input
                        type="email"
                        class="form-control form-control-light"
                        :value="this.email"
                        disabled
                >
            </div>
            <div class="form-group mb-none">
                <label class="form-control-label">Пароль</label>
                <input
                        type="password"
                        class="form-control form-control-light"
                        value="password"
                        disabled
                >
                <div class="form-group-btn-wrapper">
                    <button
                            type="button"
                            @click="onClickChangePassword"
                    >
                        Сменить пароль
                    </button>
                </div>
            </div>
        </div>
        <form novalidate class="profile-in-phone" @submit.prevent="savePhone">
            <div class="form-group mb-none">
                <label class="form-control-label">Телефон</label>
                <input
                        class="form-control form-control-light"
                        :class="{'is-invalid': errorText}"
                        type="tel"
                        placeholder="+ 375 (__) ___-__-__*"
                        v-model="profilePhone"
                        v-mask="{mask: '+ 375 (99) 999-99-99', placeholder: '_', showMaskOnHover: false}"
                        @blur="validatePhone"
                        @input="errorText = null"
                >
                <div
                        v-if="errorText"
                        class="invalid-feedback"
                >
                    {{errorText}}
                </div>

            </div>
            <button
                    class="btn btn-middle"
                    type="submit"
                    :disabled="isRequesting"

            >
                Обновить
            </button>
        </form>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    import ChangePasswordModal from '@/components/profilePage/ChangePasswordModal'
    import {ChangePasswordModalProperties} from '@/utils/modalsProperties'

    export default {
        name: "MyProfile",
        data() {
            return {
                profilePhone: null,
                errorText: null,
                isRequesting: false,
            }
        },
        computed: {
            ...mapGetters('auth', ['email']),
            ...mapGetters('profile', ['profileId', 'phone']),
            ...mapGetters('impersonalization', ['mindControlledUser']),
        },
        methods: {
            ...mapActions('profile', ['updatePhone']),
            onClickChangePassword() {
                if (this.mindControlledUser) {
                    this.$toast.error('Вы не можете изменить пароль другого пользователя')
                    return
                }

                this.$modal.show(
                    ChangePasswordModal,
                    null,
                    ChangePasswordModalProperties
                )
            },
            validatePhone(field) {
                if (!field.target.inputmask.isComplete()) {
                    this.errorText = field.target.value !== '' ? 'Некорректный номер телефона' :
                        'Поле обязательно для заполнения'
                }
            },
            async savePhone() {
                if (this.errorText) {
                    return
                }
                this.errorText = null
                this.isRequesting = true
                try {
                    await this.updatePhone({id: this.profileId, phone: this.profilePhone})
                    this.isRequesting = false
                    this.$toast.success('Телефон успешно изменен')
                } catch (e) {
                    this.isRequesting = false
                    this.$toast.error(e)
                }
            }
        },
        mounted() {
            this.profilePhone = this.phone
        }
    }
</script>

<style lang="less" scoped>
    @import '../../../assets/less/variables.less';

    .profile-in {
        width: 308px;
        text-align: left;
    }

    .profile-in-phone {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;

        .form-group {
            margin-right: 24px;
            width: 308px;
            text-align: left;
        }

        .btn {
            margin-right: 24px;
            margin-top: 25px;
            height: 44px;

            &:last-child {
                margin-right: 0;
            }
        }

        .server-error {
            margin-top: 16px;
            width: 100%;
        }
    }
</style>
