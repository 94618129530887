<template>
    <form novalidate @submit.prevent="changePassword">
        <ErrorMessage v-if="errorMessage" :error-message="errorMessage"></ErrorMessage>
        <div class="form-group form-group-password">
            <input
                    class="form-control form-control-light"
                    :class="{'is-invalid': $v.oldPassword.$error}"
                    :type="oldPasswordFieldType"
                    placeholder="Старый пароль*"
                    v-model.trim="oldPassword"
                    @keydown.space.prevent
            >
            <button
                    class="toggle-password-btn"
                    :class="{active: oldPasswordFieldType === 'text'}"
                    type="button"
                    @click="toggleOldPasswordVisibility"
            >
                    <span v-if="oldPasswordFieldType === 'password'" key="ico-visibility-off">
                        <ico name="icoVisibilityOff" color="#79919C"/>
                    </span>
                <span v-else key="ico-visibility">
                        <ico name="icoVisibility" color="#13181A"/>
                    </span>
            </button>
            <div
                    v-if="$v.oldPassword.$dirty && !$v.oldPassword.required"
                    class="invalid-feedback"
            >
                Поле обязательно для заполнения
            </div>
            <div
                    v-else-if="$v.oldPassword.$dirty && !$v.oldPassword.minLength"
                    class="invalid-feedback"
            >
                Пароль должен содержать не менее {{$v.oldPassword.$params.minLength.min}} символов
            </div>
            <div
                    v-else-if="$v.oldPassword.$dirty && !$v.oldPassword.maxLength"
                    class="invalid-feedback"
            >
                Пароль должен содержать не менее {{$v.newPassword.$params.maxLength.max}} символов
            </div>
            <div
                    v-if="$v.oldPassword.$dirty && !$v.oldPassword.strongPassword"
                    class="invalid-feedback"
            >
                Пароль должен содержать минимум 1 цифру и одну заглавную букву
            </div>
        </div>
        <div class="form-group form-group-password">
            <input
                    class="form-control form-control-light"
                    :class="{'is-invalid': $v.newPassword.$error}"
                    :type="newPasswordFieldType"
                    placeholder="Новый пароль*"
                    v-model.trim="newPassword"
                    @keydown.space.prevent
            >
            <button
                    class="toggle-password-btn"
                    :class="{active: newPasswordFieldType === 'text'}"
                    type="button"
                    @click="toggleNewPasswordVisibility"
            >
                    <span v-if="newPasswordFieldType === 'password'" key="ico-visibility-off">
                        <ico name="icoVisibilityOff" color="#79919C"/>
                    </span>
                    <span v-else key="ico-visibility">
                        <ico name="icoVisibility" color="#13181A"/>
                    </span>
            </button>
            <div
                    v-if="$v.newPassword.$dirty && !$v.newPassword.required"
                    class="invalid-feedback"
            >
                Поле обязательно для заполнения
            </div>
            <div
                    v-else-if="$v.newPassword.$dirty && !$v.newPassword.minLength"
                    class="invalid-feedback"
            >
                Пароль должен содержать не менее {{$v.newPassword.$params.minLength.min}} символов
            </div>
            <div
                    v-else-if="$v.newPassword.$dirty && !$v.newPassword.maxLength"
                    class="invalid-feedback"
            >
                Пароль должен содержать не менее {{$v.newPassword.$params.maxLength.max}} символов
            </div>
            <div
                    v-if="$v.newPassword.$dirty && !$v.newPassword.strongPassword"
                    class="invalid-feedback"
            >
                Пароль должен содержать минимум 1 цифру и одну заглавную букву
            </div>
        </div>
        <div class="form-group form-group-password">
            <input
                    class="form-control form-control-light"
                    :class="{'is-invalid': $v.confirmPassword.$error}"
                    :type="confirmPasswordFieldType"
                    placeholder="Повторите новый пароль*"
                    v-model.trim="confirmPassword"
                    @keydown.space.prevent
            >
            <button
                    class="toggle-password-btn"
                    type="button"
                    @click="toggleConfirmPasswordVisibility"
            >
                    <span v-if="confirmPasswordFieldType === 'password'" key="ico-visibility-off">
                        <ico name="icoVisibilityOff" color="#79919C"/>
                    </span>
                <span v-else key="ico-visibility">
                        <ico name="icoVisibility" color="#13181A"/>
                    </span>
            </button>
            <div
                    v-if="$v.confirmPassword.$dirty && !$v.confirmPassword.required"
                    class="invalid-feedback"
            >
                Поле обязательно для заполнения
            </div>
            <div
                    v-else-if="$v.confirmPassword.$dirty && !$v.confirmPassword.sameAsPassword"
                    class="invalid-feedback"
            >
                Пароли не совпадают
            </div>
        </div>
        <div class="form-footer">
            <button
                    class="btn btn-middle"
                    type="submit"
                    :disabled="isNotValid || isRequesting"
                    :title="isNotValid ? 'Заполните обязательные поля' : ''"
            >
                Сохранить
            </button>
        </div>
    </form>
</template>

<script>
    import {maxLength, minLength, required, sameAs} from 'vuelidate/lib/validators'
    import ErrorMessage from '@/components/common/ErrorMessage'
    import api from "../../application"

    export default {
        name: "ChangePasswordModal",
        data() {
            return {
                oldPassword: null,
                oldPasswordFieldType: 'password',
                newPassword: null,
                newPasswordFieldType: 'password',
                confirmPassword: null,
                confirmPasswordFieldType: 'password',
                errorMessage: null,
                isRequesting: false,
            }
        },
        components: {
            ErrorMessage
        },
        validations: {
            oldPassword: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(50),
                strongPassword(password) {
                    return /[A-ZА-Я]/.test(password) &&
                        /[0-9]/.test(password);
                }
            },
            newPassword: {
                required,
                minLength: minLength(8),
                maxLength: maxLength(50),
                strongPassword(password) {
                    return /[A-ZА-Я]/.test(password) &&
                        /[0-9]/.test(password);
                }
            },
            confirmPassword: {required, sameAsPassword: sameAs('newPassword')},
        },
        computed: {
            isNotValid() {
                return !this.oldPassword || !this.newPassword || !this.confirmPassword
            },
        },
        methods: {
            toggleOldPasswordVisibility() {
                this.oldPasswordFieldType = this.oldPasswordFieldType === 'password' ? 'text' : 'password'
            },
            toggleNewPasswordVisibility() {
                this.newPasswordFieldType = this.newPasswordFieldType === 'password' ? 'text' : 'password'
            },
            toggleConfirmPasswordVisibility() {
                this.confirmPasswordFieldType = this.confirmPasswordFieldType === 'password' ? 'text' : 'password'
            },
            async changePassword() {
                if (this.$v.$invalid) {
                    this.$v.$touch()
                    return
                }
                const formData = {
                    oldPassword: this.oldPassword,
                    newPassword: this.newPassword
                }

                this.isRequesting = true
                try {
                    await api.auth.changePassword(formData)
                    this.isRequesting = false
                    this.oldPassword = null
                    this.newPassword = null
                    this.$modal.close()
                    this.$toast.success('Пароль успешно изменен')
                } catch (e) {
                    this.isRequesting = false
                    this.errorMessage = e.response.data.errorMessage
                }
            }
        }
    }
</script>

<style scoped>

</style>
